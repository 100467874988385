import React from 'react';
import Herocontent from './HeroContent';
import Products from '../Products';



export default function Home() {
  return (
    <>
    <Herocontent/>
    <Products/>
   
    </>
  )
}
